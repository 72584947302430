import type {RouteLocationNormalized} from 'vue-router';

export type RedirectionToBeDone = {
    redirect: boolean;
    url?: string;
};

export function getMaybeRedirection(to: RouteLocationNormalized, host: string, queryParameters: string): RedirectionToBeDone {
    if (to && to.path) {
        const profileIdOrAlias = to.params.profileIdOrAlias;

        if (to.path.endsWith('/edit-availability') && profileIdOrAlias) {
            const targetUrl = `${host}/edit-availability/${profileIdOrAlias}?${queryParameters}`;
            return {
                redirect: true,
                url: targetUrl,
            };
        }

        const signupRedirectionPaths = ['/creation', '/completion', '/signup', '/maturity'];
        const isPathAboutSignup = signupRedirectionPaths.some((path) => to.path === path.toLowerCase() || to.path.startsWith(path + '/'));
        if (isPathAboutSignup) {
            return {
                redirect: true,
                url: `${host}/freelancer-signup${to.path}?${queryParameters}`,
            };
        }
    }
    return {
        redirect: false,
    };
}
